import React from "react"
import Img from "gatsby-image"

const ImageProducts = ({image, alt}) => {
  return(
    <div style={{ width: '240px', maxWidth: '240px', margin: '0 auto', whiteSpace: 'nowrap', textAlign: 'center' }}>
        <Img fluid={image} alt={alt} />
    </div>
  )
}

export default ImageProducts
