import React from "react"
import PropTypes from "prop-types"

import Title from "../atoms/Title"
import Box from "../atoms/Box"

import Container from "../molecules/Container"
import Header from "../organisms/Header"
import Footer from "../organisms/Footer"

import useSticky from '../hooks/Sticky'

import "../layout.css"

const Landingpage = ({children}) => {
    const { isSticky, element } = useSticky();

    return (
        <>
            <Header variant='movie' sticky={isSticky}>
                <Box style={{textAlign: 'center'}}>
                    <Title level='hero' color="#fff" element={element}>Customer marketing. <br />Motivated.</Title>
                </Box>
            </Header>
            <Container>
                <main>
                    {children}
                </main>
            </Container>
            <Footer />
        </>
    )
}

Landingpage.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Landingpage