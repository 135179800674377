import React from "react"
import Img from "gatsby-image"

const ImageCustomer = ({image, width = 140, height = 60, alt, ...props}) => {
  return(
    <div style={{ width: `${width}px`, maxWidth: `${width}px`, height: `${height}px`, margin: '1em auto', whiteSpace: 'nowrap', textAlign: 'center' }} {...props}>
        <Img fluid={image} alt={alt} style={{maxHeight: `${height}px`}} />
    </div>
  )
}

export default ImageCustomer
