import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import Landingpage from "../components/templates/Landingpage"
import SEO from "../components/atoms/Seo"
import Button from "../components/atoms/Button"
import Title from "../components/atoms/Title"
import Box from "../components/atoms/Box"
import Grid from '../components/atoms/Grid'
import GridItem from '../components/atoms/GridItem'
import BackgroundImage from '../components/atoms/BackgroundImage'
import ImageCustomer from "../components/atoms/images/ImageCustomer"
import Icon from "../components/atoms/Icon"
import Divider from "../components/atoms/Divider"
import ImageProducts from "../components/atoms/images/ImageProducts"
import ImageMarketing from "../components/atoms/images/ImageMarketing"
import WelcomeVideo from "../components/molecules/WelcomeVideo"

const IndexPage = () => {
    const data = useStaticQuery(graphql`
        query IndexData {
            one: file(relativePath: { eq: "logos/nordicbet.png" }) {
                childImageSharp {
                    fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid
                    }
                }
            },
            two: file(relativePath: { eq: "logos/sportingbet.png" }) {
                childImageSharp {
                    fluid(maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                    }
                }
            },
            three: file(relativePath: { eq: "logos/pokerstars.png" }) {
                childImageSharp {
                    fluid(maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                    }
                }
            },
            four: file(relativePath: { eq: "logos/gunsroses.png" }) {
                childImageSharp {
                    fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                    }
                }
            },
            five: file(relativePath: { eq: "logos/torontomapleleafs.png" }) {
                childImageSharp {
                    fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                    }
                }
            },
            fiftyfridays: file(relativePath: { eq: "products/5050Fridays.png" }) {
                childImageSharp {
                    fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid
                    }
                }
            },
            rush: file(relativePath: { eq: "products/Rush.png" }) {
                childImageSharp {
                    fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid
                    }
                }
            },
            championscasino: file(relativePath: { eq: "products/ChampionsOfCasino.png" }) {
                childImageSharp {
                    fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid
                    }
                }
            },
            boxes: file(relativePath: { eq: "marketing/boxes.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                    }
                }
            },
            egr: file(relativePath: { eq: "landingpage/egr-white.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1500) {
                    ...GatsbyImageSharpFluid
                    }
                }
            },
        }
    `)

    return (
        <Landingpage>
            <SEO 
            title="Customer marketing. Motivated. from A GAME ABOVE"
            description="Welcome to A GAME ABOVE. We consult, innovate and create solutions built upon the customer experience to effect engagement, enhance lifetime value and extend lifecycle."
            keywords="marketing agency, experience marketing agency, igaming marketing agency, igaming marketing, gambling marketing, casino marketing, omni channel marketing, online betting marketing, sales promotions agency, ismail vali, paul girvan, game above, a game above, marketing, igaming, promos, campaigns"
            />

            <BackgroundImage color='Gold' style={{padding: '2rem'}}>
                <div style={{ width: '400px', maxWidth: '100%', margin: '0 auto', whiteSpace: 'nowrap', textAlign: 'center' }}>
                    <Img fluid={data.egr.childImageSharp.fluid} alt='A GAME ABOVE - EGR, Marketing & Innovation Winner' />
                </div>
            </BackgroundImage>

            <Box color="grey">
                <Title level={2}>What we do</Title>
                <Title level={3}>We help you gain, retain & maintain players</Title>
                <p style={{textAlign: 'center', margin: '0 auto 16px'}}>We innovate and create unique campaigns, stories and solutions that give your audience meaningful experiences to make them customers.</p>
                <p style={{textAlign: 'center', margin: '0 auto 16px'}}>We work to help you engage, convert, acquire, monetize and build lifecycle customer journeys with industry-leading low CPAs and high ROIs across the lifetime value chain.</p>
                <p style={{textAlign: 'center', margin: '0 auto 16px'}}>We build campaigns, promotions and events for you that work on property, online and via an omni-channel, hybrid approach to unlock business across all the channels used by your audience.</p>
            </Box>

            <BackgroundImage color='Blue'>
                <Box>
                    <Grid>
                        <GridItem size='55%' gridOrder='2'>
                            <Title level={2} color={'#fff'} position={'left'}>Why are we hoping to work for you?</Title>
                            <p><strong>Our Concept</strong></p>
                            <p>A unified marketing and promotional effort spanning land-based and online to boost GGR via a focus on cross-fertilization</p>
                            
                            <p><strong>Our Request</strong></p>
                            <p>An opportunity to prove our concepts through a trial project applying promotions and campaigns to your brand, products & services using our unique, patented award-winning approach</p>
                            
                            <p><strong>Our Commitment</strong></p>
                            <p>1) We will work jointly with your marketing department to deliver a measurable impact on GGR, online and land-based</p>
                            <p>2) Exclusivity within your market – conferring a competitive advantage</p>
                        </GridItem>
                        <GridItem size='35%' gridOrder='1'>
                            <Icon icon="maze" color='white' background='#A2845C' />
                        </GridItem>
                    </Grid>
                </Box>
            </BackgroundImage>

            <Divider />
            <Title level={2} position={'center'}>Market-making performance</Title>
            <Title level={3} position={'center'}>Select experience of the team</Title>
            <Box>
                <Grid>
                    <GridItem size='20%'>
                        <ImageCustomer style={{margin: '0 auto'}} image={data.one.childImageSharp.fluid} alt='Market-making performance - A GAME ABOVE' />
                    </GridItem>
                    <GridItem size='20%'>
                        <ImageCustomer image={data.two.childImageSharp.fluid} alt='Market-making performance - A GAME ABOVE' />
                    </GridItem>
                    <GridItem size='20%'>
                        <ImageCustomer style={{margin: '0 auto'}} image={data.three.childImageSharp.fluid} alt='Market-making performance - A GAME ABOVE' />
                    </GridItem>
                </Grid>
                <Grid>
                <GridItem size='20%'>
                        <ImageCustomer image={data.four.childImageSharp.fluid} height="140" alt='Market-making performance - A GAME ABOVE' />
                    </GridItem>
                    <GridItem size='20%'>
                        <ImageCustomer image={data.five.childImageSharp.fluid} height="140" alt='Market-making performance - A GAME ABOVE' />
                    </GridItem>
                </Grid>
            </Box>
            <Divider />

            <BackgroundImage color='Blue'>
                <Box>
                    <Grid>
                        <GridItem size='35%' gridOrder='1'>
                            <Icon icon="story" color='white' background='#A2845C' />
                        </GridItem>
                        <GridItem size='55%' gridOrder='2'>
                            <Title level={2} color={'#fff'} position={'left'}>With products built on desire, players need stories…</Title>
                            <ul>
                                <li>Consumers need experiences that create trial, return & repeat activity. We innovate the campaigns that justify those journeys.</li>
                                <li>We get to know your requirements, products, brand, community and service, and propose creative campaigns and player events that will add value across your business missions</li>
                                <li>Working together, we’ll propose and optimize solutions for your brand, product or service that will help you inspire and motivate your customers: We literally help you provide the game above the gambling</li>
                                <li>The customer experience solutions we innovate for you will add value across your products and service, and scale across your acquisition, retention, reactivation and revised CRM missions</li>
                                <li>Work with us to create stories for customers that</li>
                                <li style={{marginLeft: '32px'}}>Effect engagement</li>
                                <li style={{marginLeft: '32px'}}>Extend lifecycle</li>
                                <li style={{marginLeft: '32px'}}>Enhance lifetime value</li>
                            </ul>
                        </GridItem>
                    </Grid>
                </Box>
            </BackgroundImage>

            <Divider />
            <Title level={2} position={'center'}>Delight audience,<br /> demand decisions,<br /> drive activity</Title>
            <Box>
                <Grid>
                    <GridItem size='33%'>
                        <ImageProducts image={data.fiftyfridays.childImageSharp.fluid} alt='50/50 Fridays - A GAME ABOVE' />
                    </GridItem>
                    <GridItem size='33%'>
                        <ImageProducts image={data.championscasino.childImageSharp.fluid} alt='Champions Of Casino - A GAME ABOVE' />
                    </GridItem>
                    <GridItem size='33%'>
                        <ImageProducts image={data.rush.childImageSharp.fluid} alt='Rush - A GAME ABOVE' />
                    </GridItem>
                </Grid>
            </Box>

            <BackgroundImage color='Blue'>
                <Box color="blueBackground">
                    <Grid>
                        <GridItem size='55%' gridOrder='2'>
                            <Title level={2} color={'#fff'} position={'left'}>Play, Earn, Return:</Title>
                            <Title level={3} color={'#fff'} position={'left'}>Inspire and motivate effortless loyalty</Title>
                            <ul>
                                <li>
                                    Change the marketing approach to positively impact bottom line revenue & profit                                    
                                </li>
                                <li>Gain, retain and maintain when customers play, earn and return across all channels – the “lifecycle loop”</li>
                                        <li>Meet customers across all channels – on property, online and hybrid – with the same campaigns, benefiting all groups of players</li>
                                        <li>Our Lifecycle Loop campaigns deliver lower CPA, higher ROI and long-term customer relationships. By working across the audience and upon all player segments, we motivate play-earn-return dynamics to produce sustainable, loyal and entertained customers</li>
                            </ul>
                        </GridItem>
                        <GridItem size='35%' gridOrder='1'>
                            <Icon icon="loop" color='white' background='#A2845C' size='348' />
                        </GridItem>
                    </Grid>
                </Box>
            </BackgroundImage>

            <Box>
                <Title level={2} position={'center'}>Customer marketing</Title>
                <Title level={3} position={'center'}>Working with your team, we make the players the stars of the show(business)</Title>
                <ImageMarketing image={data.boxes.childImageSharp.fluid} alt='Customer marketing - A GAME ABOVE' />
            </Box>

            <BackgroundImage color='Blue'>
                <Box color="blueBackground">
                    <Grid>
                        <GridItem size='55%' gridOrder='2'>
                            <Title level={2} color={'#fff'} position={'left'}>Target success:</Title>
                            <Title level={3} color={'#fff'} position={'left'}>Inspire customers to increase revenue</Title>
                            <ul>
                                <li>
                                    <strong>Our Request</strong><br />An opportunity to prove our concepts to you through a trial project by applying our patented, unique and proven promotions and campaigns to your brand, products & services 
                                </li>
                                <li>Let’s choose a pilot project and test/measure/scale it over your business</li>
                                <li>Working with your marketing team, we research and create campaigns that count with solutions that harness your team and tech to effect customer and community engagement and activity</li>
                                <li>We enhance lifetime value and extend lifecycle whilst ensuring players are protected and all regulatory rules are respected</li>
                                <li>Player protection is always a part of the customer experience, overall – not an added extra</li>
                                <li>We innovate, optimize and deliver these campaigns and solutions alongside your team, creating a powerful sum of the parts, to make your customers believe and engage, reliably and repeatedly, to inspire loyalty and increase revenue</li>

                            </ul>
                        </GridItem>
                        <GridItem size='35%' gridOrder='1'>
                            <Icon icon="targetsuccess" color='white' background='#A2845C' />
                        </GridItem>
                    </Grid>
                </Box>
            </BackgroundImage>

            <WelcomeVideo />
            
            <Box color="grey">
                <Grid>
                    <GridItem size='15%' gridOrder='1'></GridItem>
                    <GridItem size='70%' gridOrder='2'>
                        <Title level={2} position={'center'}>About us</Title>
                        <p style={{textAlign: 'center', margin: '0 0 32px'}}>
                            Get in touch with A GAME ABOVE and learn about the team
                        </p>
                        <Button background={'#24859B'} backgroundHover={'#24859B'} position={'center'} link="/about-us">Get in touch</Button>
                    </GridItem>
                    <GridItem size='15%' gridOrder='3'></GridItem>
                </Grid>
            </Box>
        </Landingpage>
    )
}

export default IndexPage
