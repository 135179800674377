import React from "react"
import Img from "gatsby-image"

const ImageMarketing = ({image, alt}) => {
  return(
    <div style={{ width: '1000px', maxWidth: '100%', margin: '0 auto', whiteSpace: 'nowrap', textAlign: 'center' }}>
        <Img fluid={image} alt={alt} />
    </div>
  )
}

export default ImageMarketing
